@import url('../../less/variables.less');
@import (reference) url('~antd/dist/antd.less');

.main-menu {
  position: relative;
  z-index: 4;
  height: 100%;
  display: flex;
  flex-direction: column;
  &__container {
    background: none;
  }
  &__logo {
    max-width: 100px;
    min-height: 76px;
    margin: @padding-sm auto;
    img {
      width: 100%;
    }
    .ant-layout-sider-collapsed & {
      min-height: 0;
    }
  }
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji';@primary-color: #c44972;@primary-color-light: #d16f8c;@primary-color-lighter: #de99ab;@primary-color-lightest: #ebc7d0;@disabled-primary-color: #f7e9eb;@text-color-secondary: @primary-color;@degrade-menu: 99deg, #c44972 0%, #d16f8c 58%, #de99ab 100%;@border-radius-base: 5px;@default-margin: 5px;@progress-default-color: @primary-color;@table-header-color: #ffffff;@primary-color-text: #2b0919;@dark-grey-text: darken(@disabled-primary-color, 10%);@border-color: #cccccc;@background-color: #f8f8f8;@background-dark-color: #f0f2f5;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);@box-shadow-reverse: 0 -2px 4px rgba(50, 64, 77, 0.2);@border-base: 1px solid @border-color;@valid-green: #52c41a;@invalid-red: #f10303;@warn-yellow: #fab62e;@grey-1: #ffffff;@grey-2: #fafafa;@grey-3: #f5f5f5;@grey-4: #e8e8e8;@grey-5: #d9d9d9;@grey-6: #bfbfbf;@grey-7: #8c8c8c;@grey-8: #595959;@grey-9: #262626;@grey-10: #000000;@table-header-bg: @primary-color;@table-row-hover-bg: @primary-color-lightest;@process-icon-color: @primary-color-lighter;@finish-tail-color: @primary-color-lighter;@process-title-color: @grey-7;@wait-title-color: @grey-7;@finish-title-color: @grey-7;