@import url('~antd/dist/antd.less');
@import url('ant-custom.less');
@import url('variables.less');

html {
  font-size: 100%;
	-webkit-font-smoothing: antialiased;
}
body {
  overflow-x: hidden;
  overflow-y: auto;
}
#root,
#root > * {
  min-height: 100vh;
}

input,
button,
select,
optgroup,
textarea,
a,
h1,
h2,
h3,
h4 h5,
h6 {
  font-family: @font-family;
}

// Images
img {
  border: 0;
  vertical-align: top;
  max-width: 100%;
}

.translation-error {
  padding: 20%;
  .ant-alert {
    margin: 0 20%;
    font-size: 1.2rem;
  }
}

.fill-parent {
  position: relative;
  width: 100%;
  height: 100%;
}
.absolute-centered {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/** Overide default ant-card style because no option were provided for this **/
.ant-card {
  height: 100%;
  box-shadow: @shadow-2;
  border-radius: @border-radius-base;
}

/** utility classes **/
.text {
  &-center {
    text-align: center;
  }
  &-left {
    text-align: left;
  }
  &-right {
    text-align: right;
  }
  &-sm {
    @media screen and (min-width: @screen-sm-min) {
      &-center {
        text-align: center;
      }
      &-left {
        text-align: left;
      }
      &-right {
        text-align: right;
      }
    }
  }
  &-md {
    @media screen and (min-width: @screen-md-min) {
      &-center {
        text-align: center;
      }
      &-left {
        text-align: left;
      }
      &-right {
        text-align: right;
      }
    }
  }
  &-lg {
    @media screen and (min-width: @screen-lg-min) {
      &-center {
        text-align: center;
      }
      &-left {
        text-align: left;
      }
      &-right {
        text-align: right;
      }
    }
  }
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji';@primary-color: #c44972;@primary-color-light: #d16f8c;@primary-color-lighter: #de99ab;@primary-color-lightest: #ebc7d0;@disabled-primary-color: #f7e9eb;@text-color-secondary: @primary-color;@degrade-menu: 99deg, #c44972 0%, #d16f8c 58%, #de99ab 100%;@border-radius-base: 5px;@default-margin: 5px;@progress-default-color: @primary-color;@table-header-color: #ffffff;@primary-color-text: #2b0919;@dark-grey-text: darken(@disabled-primary-color, 10%);@border-color: #cccccc;@background-color: #f8f8f8;@background-dark-color: #f0f2f5;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);@box-shadow-reverse: 0 -2px 4px rgba(50, 64, 77, 0.2);@border-base: 1px solid @border-color;@valid-green: #52c41a;@invalid-red: #f10303;@warn-yellow: #fab62e;@grey-1: #ffffff;@grey-2: #fafafa;@grey-3: #f5f5f5;@grey-4: #e8e8e8;@grey-5: #d9d9d9;@grey-6: #bfbfbf;@grey-7: #8c8c8c;@grey-8: #595959;@grey-9: #262626;@grey-10: #000000;@table-header-bg: @primary-color;@table-row-hover-bg: @primary-color-lightest;@process-icon-color: @primary-color-lighter;@finish-tail-color: @primary-color-lighter;@process-title-color: @grey-7;@wait-title-color: @grey-7;@finish-title-color: @grey-7;